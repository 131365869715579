<template>
  <div class="railcar railcar--info pixel-font" :title="getRailcarProp('name')">
    <ul>
      <li>
        <span :class="volume != capacity ? 'red' : ''">{{ volume }}/{{ capacity }}</span>
      </li>
      <li>wgt {{ weight }}</li>
      <li>pay {{ currentPayout }} ({{ payout }})</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    railcar: {
      id: String,
      name: String,
      capacity: Number,
      img: String,
      rarity: String,
      type: String,
      payout: Number,
      currentPayout: Number,
      tip: Number,
      commodity_types: String,
      cargo: [
        {
          id: String,
          img: String,
          name: String,
          payout: Number,
          rarity: String,
          rate: Number,
          tip: Number,
          type: String,
          volume: String,
          weight: String
        }
      ]
    }
  },
  data() {
    return {
      capacity: this.getRailcarProp("capacity"),
      currentPayout: this.getRailcarProp("currentPayout"),
      payout: this.getRailcarProp("payout"),
      weight: this.calculateWeight(),
      volume: this.calculateVolume()
    };
  },
  methods: {
    getRailcarProp(prop) {
      const railcar = JSON.parse(this.railcar);

      return railcar[prop];
    },
    calculateWeight() {
      const cargo = this.getRailcarProp("cargo");
      const commodityType = this.getRailcarProp("commodity_types");

      if (!commodityType) {
        return this.getRailcarProp("weight");
      }

      return cargo.reduce((acc, curr) => acc + parseInt(curr.weight), 0);
    },
    calculateVolume() {
      const cargo = this.getRailcarProp("cargo");
      const commodityType = this.getRailcarProp("commodity_types");

      if (!commodityType) {
        return cargo.length;
      }

      return cargo.reduce((acc, curr) => acc + parseInt(curr.volume), 0);
    }
  }
};
</script>

<style :scoped>
.railcar {
  &.railcar--info {
    display: flex;

    .red {
      color: red;
    }

    > ul {
      list-style: none;
      padding: 0;
      margin: 0;

      > li {
        padding: 0;
        margin: 0;
      }
    }
  }
}
</style>

<template>
  <details class="railcar railcar--container">
    <summary class="railcar railcar--box">
      <img
        class="railcar railcar--image"
        :src="getRailcarImage()"
        :title="getRailcarProp('name') + ' (' + id + ')'"
      />
    </summary>
    <span>
      <info-component
        class="info"
        :key="id"
        :railcar="railcar"
      ></info-component>
      <div class="railcar railcar--cargo info-font">
        <p
          v-for="item in cargo"
          :key="item.id"
          :class="`rarity rarity--${item.rarity.toLowerCase()}`"
          :title="item.id"
        >
          {{ item.name }}
          <span class="color color--gray">({{ item.volume }})</span>
        </p>
      </div>
    </span>
  </details>
</template>

<script>
export default {
  props: {
    railcar: {
      id: String,
      name: String,
      capacity: Number,
      img: String,
      rarity: String,
      type: String,
      payout: Number,
      currentPayout: Number,
      tip: Number,
      commodity_types: String,
      cargo: [
        {
          id: String,
          img: String,
          name: String,
          payout: Number,
          rarity: String,
          rate: Number,
          tip: Number,
          type: String,
          volume: String,
          weight: String
        }
      ]
    }
  },
  data() {
    return {
      id: this.getRailcarProp("id"),
      cargo: this.getRailcarProp("cargo"),
      tooltipClass: "hidden"
    };
  },
  methods: {
    getRailcarImage() {
      const railcar = JSON.parse(this.railcar);
      const { type, name } = railcar;

      const size = name.split(" ")[0].toLowerCase();

      return `/images/${type || "passenger"}_${size}.png`;
    },
    getRailcarProp(prop) {
      const railcar = JSON.parse(this.railcar);

      return railcar[prop];
    }
  },
  mounted() {}
};
</script>

<style :scoped>
.railcar {
  &.railcar--container {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0 0 20px;
  }

  &.railcar--cargo {
    font-size: 10px;

    > p {
      margin: 5px 0;
    }
  }

  &.railcar--tooltip {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 99;
    cursor: pointer;
    background-color: white;
    border: 1px solid black;
    border-radius: 7px;
    padding: 10px;
    width: 110px;

    > h5 {
      font-size: 14px;
      line-height: 15px;
      margin: 0 0 8px;
    }

    &.hidden {
      display: none;
    }
  }

  &.railcar--container {
    width: 66px;
  }

  &.railcar--box {
    display: flex;
    justify-content: center;
  }

  &.railcar--image {
    cursor: pointer;
    height: 34px;
  }
}
</style>

<template>
  <div
    :class="'railcar railcar--tooltip ' + tooltipClass"
  >
    <h5>{{ name }}</h5>
    <ul v-for="item in cargo" :key="item.id">
      <li :class="'cargo cargo--' + item.rarity">
        {{ item.name }}
        <span v-if="item.weight">
          ({{ item.weight }})
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    tooltipClass: String,
    railcar: {
      id: String,
      name: String,
      capacity: Number,
      img: String,
      rarity: String,
      type: String,
      payout: Number,
      currentPayout: Number,
      tip: Number,
      commodity_types: String,
      cargo: [
        {
          id: String,
          img: String,
          name: String,
          payout: Number,
          rarity: String,
          rate: Number,
          tip: Number,
          type: String,
          volume: String,
          weight: String
        }
      ]
    }
  },
  data() {
    return {
      capacity: this.getRailcarProp("capacity"),
      currentPayout: this.getRailcarProp("currentPayout"),
      cargo: this.getRailcarProp("cargo"),
      id: this.getRailcarProp("id"),
      name: this.getRailcarProp("name"),
      payout: this.getRailcarProp("payout"),
      weight: this.calculateWeight(),
      volume: this.calculateVolume()
    };
  },
  methods: {
    getRailcarProp(prop) {
      const railcar = JSON.parse(this.railcar);

      return railcar[prop];
    },
    calculateWeight() {
      if (!this.cargo) {
        return;
      }
      return this.cargo.reduce((acc, curr) => acc + parseInt(curr.weight), 0);
    },
    calculateVolume() {
      if (!this.cargo) {
        return;
      }
      return this.cargo.reduce((acc, curr) => acc + parseInt(curr.volume), 0);
    }
  }
};
</script>

<style :scoped>
.railcar {
  &.railcar--tooltip {
    font-family: "Arial", monospace;
    font-size: 13px;
    user-select: none;
    pointer-events: none;

    > ul {
      list-style: disc;
      padding: 0 15px;
      margin: 0;

      > li {
        padding: 2px;
        margin: 0;
      }
    }
  }
}
</style>

<template>
  <li class="square-dot">
    <span
      :class="`commodity commodity--name info-font rarity rarity--${asset.rarity.toLowerCase()}`"
      >{{ asset.name }}</span
    >
  </li>
</template>

<script>
export default {
  props: {
    asset: {}
  },
  data() {
    return {};
  },
  mounted() {}
};
</script>

<style>
.color {
  &.color--black {
    color: black;
  }
  &.color--gray {
    color: gray;
  }
}

.rarity {
  &.rarity--common {
    color: saddlebrown;
  }
  &.rarity--uncommon {
    color: green;
  }
  &.rarity--rare {
    color: cornflowerblue;
  }
  &.rarity--epic {
    color: mediumorchid;
  }
  &.rarity--legendary {
    color: orange;
  }
  &.rarity--mythic {
    color: red;
  }
}
</style>

<style :scoped>
.asset {
  &.asset--name {
    display: flex;
  }
}
</style>

<template>
  <details>
    <summary class="train">
      <span class="main-font font-weight-600">{{ train.name }}</span>
      <span class="arrow-separator main-font">></span>
      <span class="pixel-font">dist {{ train.locomotive.distance_total }}</span>
      <span class="pipe-separator pixel-font">|</span>
      <span class="pixel-font">haul {{ train.locomotive.haul_total }}</span>
      <span class="pipe-separator pixel-font">|</span>
      <span class="pixel-font">speed {{ train.locomotive.speed_total }}</span>
      <span class="pipe-separator pixel-font">|</span>
      <span class="pixel-font">luck {{ train.locomotive.luck_total }}</span>
      <span class="pipe-separator pixel-font">|</span>
      <span class="pixel-font">reward 0 TOC</span>
      <span class="icon icon--open-all" @click.stop="showDetails">+</span>
    </summary>
    <span>
      <div class="train train--stats pixel-font">
        est. pay {{ train.currentPayout }} ({{ train.payout }}) from
        {{ train.fromStation }} to {{ train.toStation }} ({{ train.distance }})
      </div>
      <div class="train train--conductor pixel-font">
        <span class="font-weight-600">{{ train.conductor.name }}</span>
        <span>: </span>
        <span>{{ train.conductor.perk }}</span>
        <span class="perk font-weight-600">{{
          train.conductor.perk_boost
        }}</span>
        <span v-if="train.conductor.perk2">
          <span class="pipe-separator pixel-font">|</span>
          <span>{{ train.conductor.perk2 }}</span>
          <span class="perk font-weight-600">
            {{ train.conductor.perk_boost2 }}</span
          >
        </span>
      </div>
      <div class="train train--locomotive pixel-font">
        <span class="font-weight-600">{{ train.locomotive.name }} </span>
        <span>({{ train.locomotive.composition }}): </span>
        <span class="font-weight-600"
          >{{ train.totalHaul }}/{{ train.locomotive.haul_total }}</span
        >
      </div>
      <ul class="train train--locomotive-stats pixel-font">
        <li>
          distance {{ train.locomotive.distance_total }} base
          {{ train.locomotive.distance }} boost +{{
            train.locomotive.distance_boost
          }}/{{ train.locomotive.distance_boost_max }}
        </li>
        <li>
          haul {{ train.locomotive.haul_total }} base
          {{ train.locomotive.hauling_power }} boost +{{
            train.locomotive.haul_boost
          }}/{{ train.locomotive.haul_boost_max }}
        </li>
        <li>
          speed {{ train.locomotive.speed_total }} base
          {{ train.locomotive.speed }} boost +{{
            train.locomotive.speed_boost
          }}/{{ train.locomotive.speed_boost_max }}
        </li>
        <li>
          luck {{ train.locomotive.luck_total }} base
          {{ train.locomotive.luck }} boost +{{
            train.locomotive.luck_boost
          }}/{{ train.locomotive.luck_boost_max }}
        </li>
      </ul>
    </span>
  </details>
  <div>
    <railcar-component
      v-for="railcar in train.railcars"
      :key="railcar[0].id"
      :railcar="JSON.stringify(railcar[0])"
    >
    </railcar-component>
  </div>
</template>

<script>
export default {
  props: {
    train: {
      name: String,
      currentPayout: Number,
      payout: Number,
      tip: Number,
      from: String,
      to: String,
      fromStation: String,
      toStation: String,
      distance: Number,
      conductor: {
        id: String,
        img: String,
        name: String
      },
      locomotive: {
        id: String,
        img: String,
        name: String
      },
      railcars: [
        {
          id: String,
          name: String,
          capacity: Number,
          img: String,
          rarity: String,
          type: String,
          payout: Number,
          currentPayout: Number,
          tip: Number,
          commodity_types: String
        }
      ]
    }
  },
  data() {
    return {};
  },
  methods: {
    getRailcar(railcar) {
      return railcar;
    },
    showDetails(event) {
      setTimeout(() => {
        const target = event.target.parentElement.parentElement;
        if (target && target.hasAttribute("open")) {
          this.openAll();
        } else {
          this.closeAll();
        }
      });
    },
    openAll() {
      const details = document.querySelectorAll("details");
      details.forEach((detail) => detail.setAttribute("open", true));
    },
    closeAll() {
      const details = document.querySelectorAll("details");
      details.forEach((detail) => detail.removeAttribute("open"));
    },
    mounted() {}
  }
};
</script>

<style :scoped>
.perk {
  padding-left: 5px;
}

.train--locomotive-stats {
  list-style: square;
  margin: 10px 20px 15px;
  padding: 0;
}

.train--conductor {
  margin: 10px 0;
}

.icon {
  position: relative;
  top: 0.2rem;
  right: -0.5rem;
  font-size: 1.5rem;
  font-weight: 800;
}
</style>

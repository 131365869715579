<template>
  <div class="chart">
    <chart-component></chart-component>
  </div>
  <div class="container">
    <section class="trains">
      <span
        class="square-font font-weight-600 uppercase title {{ asset.rarity }}"
        >Trains</span
      >
      <ul class="trains-container columns-1">
        <train-component
          v-for="train in trains"
          :key="train.asset_id"
          :train="train"
        ></train-component>
      </ul>
    </section>
    <section class="assets">
      <span class="square-font font-weight-600 uppercase title"
        >Commodities available</span
      >
      <ul class="columns columns-3 commodities-container">
        <asset-component
          v-for="commodity in commodities"
          :key="commodity.asset_id"
          :asset="commodity"
        ></asset-component>
      </ul>
      <span class="square-font font-weight-600 uppercase title"
        >Railcars available</span
      >
      <ul class="columns columns-3 railcars-container">
        <asset-component
          v-for="railcar in railcars"
          :key="railcar.asset_id"
          :asset="railcar"
        ></asset-component>
      </ul>
      <span class="square-font font-weight-600 uppercase title"
        >Conductors available</span
      >
      <ul class="columns columns-3 conductors-container">
        <asset-component
          v-for="conductor in conductors"
          :key="conductor.asset_id"
          :asset="conductor"
        ></asset-component>
      </ul>
      <span class="square-font font-weight-600 uppercase title"
        >Locomotives available</span
      >
      <ul class="columns columns-3 locomotives-container">
        <asset-component
          v-for="locomotive in locomotives"
          :key="locomotive.asset_id"
          :asset="locomotive"
        ></asset-component>
      </ul>
      <span class="square-font font-weight-600 uppercase title"
        >Passengers available</span
      >
      <ul class="columns columns-3 passengers-container">
        <asset-component
          v-for="passenger in passengers"
          :key="passenger.asset_id"
          :asset="passenger"
        ></asset-component>
      </ul>
    </section>
  </div>
</template>

<script>
import { getTrainInfo, getAssetsAvailable } from "./services/api";

export default {
  data() {
    return {
      trains: [],
      commodities: [],
      conductors: [],
      railcars: [],
      locomotives: [],
      passengers: []
    };
  },
  async beforeMount() {
    const trains = await getTrainInfo("all");
    const { commodities, conductors, railcars, locomotives, passengers } =
      await getAssetsAvailable();

    this.trains = [...trains];
    this.commodities = this.doSort([...commodities], "rarity", "name");
    this.conductors = this.doSort([...conductors], "rarity", "name");
    this.railcars = this.doSort([...railcars], "rarity", "name");
    this.locomotives = this.doSort([...locomotives], "rarity", "name");
    this.passengers = this.doSort([...passengers], "rarity", "name");
  },
  methods: {
    doSort(items, property = "name", property2 = null) {
      return items.sort((a, b) => {
        if (a[property] === b[property] && property2) {
          return a[property2].localeCompare(b[property2]);
        }

        return a[property].localeCompare(b[property]);
      });
    }
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Reem+Kufi:wght@400;500;600;700&family=VT323&display=swap");

body {
  user-select: none;
  background-color: rgb(226, 241, 247);
}

img {
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
}

details {
  cursor: pointer;
}

details summary > * {
  display: inline;
}

summary {
  list-style-type: none;
}

summary::-webkit-details-marker {
  display: none;
}

.main-font {
  font-family: "Reem Kufi", sans-serif;
  font-size: 1.2rem;
}

.info-font {
  font-family: monospace;
}

.square-font {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.pixel-font {
  font-family: "VT323", monospace;
  font-size: 1rem;
}

.columns-1 {
  column-count: 1;
  gap: 0;
}

.columns-3 {
  column-count: 3;
  gap: 5rem;
}

.commodities-container,
.railcars-container,
.conductors-container,
.locomotives-container,
.passengers-container {
  & > li {
    width: 8rem;
  }
}

.arrow-separator,
.pipe-separator {
  padding: 0 5px;
}

.font-weight-600 {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.color {
  &.color--black {
    color: black;
  }
  &.color--gray {
    color: gray;
  }
}

.rarity {
  &.rarity--common {
    color: saddlebrown;
  }
  &.rarity--uncommon {
    color: green;
  }
  &.rarity--rare {
    color: cornflowerblue;
  }
  &.rarity--epic {
    color: mediumorchid;
  }
  &.rarity--legendary {
    color: orange;
  }
  &.rarity--mythic {
    color: red;
  }
}

.square-dot {
  list-style: square;
}
</style>

<style :scoped>
.container {
  display: inline-flex;
  width: 100%;
  margin: 1rem 0 0;
}

.title {
  font-size: 1.1rem;
}

.trains-container {
  padding: 0;
  margin: 1rem 0 0;
}

.trains {
  display: flex;
  flex-direction: column;
  width: 65%;
  margin: 0 0 0 7%;
}

.assets {
  display: flex;
  flex-direction: column;
  width: 35%;
  margin: 0 7% 0 0;
}
</style>

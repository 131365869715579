<template>
  <div class="chart-container chart-todays-rewards">
    <GChart
      class="chart"
      type="PieChart"
      v-if="isTodaysRewardsChartLoaded"
      :data="todaysRewardsChartData"
      :options="todaysRewardsChartOptions"
    />
    <img
      class="loading"
      src="/images/loading/todays_rewards_chart_skeleton.png"
      v-if="!isTodaysRewardsChartLoaded"
    />
  </div>
  <div class="chart-container chart-last-7-days-rewards-by-train">
    <GChart
      class="chart"
      type="LineChart"
      v-if="isLast7DaysRewardsChartLoaded"
      :data="last7DaysRewardsChartData"
      :options="last7DaysRewardsChartOptions"
    />
    <img
      class="loading"
      src="/images/loading/last_7days_rewards_chart_skeleton.png"
      v-if="!isLast7DaysRewardsChartLoaded"
    />
  </div>
  <div class="chart-container chart-last-7-days-drops-by-train">
    <GChart
      class="chart"
      type="ColumnChart"
      v-if="isLast7DaysDropsChartLoaded"
      :data="last7DaysDropsChartData"
      :options="last7DaysDropsChartOptions"
    />
    <img
      class="loading"
      src="/images/loading/last_7days_drop_chart_skeleton.png"
      v-if="!isLast7DaysDropsChartLoaded"
    />
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";

import {
  getLast7DaysRewardsChartData,
  getLast7DaysDropsChartData,
  getTodaysRewardsChartData
} from "../services/api";

const last7DaysRewardsChartOptions = {
  title: "Last 7 days rewards",
  curveType: "function",
  legend: { position: "bottom", maxLines: 5 },
  backgroundColor: "rgb(226, 241, 247)",
  height: 400,
  hAxis: {
    format: "short"
  },
  vAxis: {
    title: "tocium",
    format: "short",
    fontSize: "10px",
    ticks: [0],
    viewWindow: { max: 0 },
    gridlines: { color: "#bbb", count: 20 }
  },
  animation: { duration: 2, easing: "inAndOut" }
};

const last7DaysDropsChartOptions = {
  title: "Last 7 days drops",
  legend: { position: "bottom", maxLines: 5 },
  backgroundColor: "rgb(226, 241, 247)",
  height: 400,
  bar: { groupWidth: "75%" },
  isStacked: true,
  tooltip: { isHtml: true },
  animation: { duration: 2, easing: "inAndOut" }
};

const todaysRewardsChartOptions = {
  title: "Today's rewards",
  legend: { position: "bottom", maxLines: 5 },
  backgroundColor: "rgb(226, 241, 247)",
  height: 400,
  pieHole: 0.3,
  is3D: true,
  tooltip: { isHtml: true },
  animation: { duration: 2, easing: "inAndOut" }
};

export default {
  components: { GChart },
  data() {
    return {
      isLast7DaysRewardsChartLoaded: false,
      last7DaysRewardsChartData: [],
      last7DaysRewardsChartOptions: last7DaysRewardsChartOptions,
      isLast7DaysDropsChartLoaded: false,
      last7DaysDropsChartData: [],
      last7DaysDropsChartOptions: last7DaysDropsChartOptions,
      isTodaysRewardsChartLoaded: false,
      todaysRewardsChartData: [],
      todaysRewardsChartOptions: todaysRewardsChartOptions
    };
  },
  async beforeMount() {
    const CHART_OFFSET_Y = 10000;

    this.last7DaysRewardsChartData = await getLast7DaysRewardsChartData();
    this.last7DaysDropsChartData = await getLast7DaysDropsChartData();
    this.todaysRewardsChartData = await getTodaysRewardsChartData();

    const maxValues = this.last7DaysRewardsChartData.map((items) => {
      return items.reduce((acc, curr) => {
        if (typeof curr === "number" && curr > acc) {
          return curr;
        }
        return acc;
      }, 0);
    });

    const max = Math.max(...maxValues) + CHART_OFFSET_Y;

    const ticks = [0];
    for (let i = 0; i < max; i = i + 50000) {
      ticks.push(i);
    }

    this.last7DaysRewardsChartOptions.vAxis.viewWindow.max = max;
    this.last7DaysRewardsChartOptions.vAxis.ticks = ticks;

    this.isLast7DaysRewardsChartLoaded = true;
    this.isLast7DaysDropsChartLoaded = true;
    this.isTodaysRewardsChartLoaded = true;
  }
};
</script>

<style :scoped>
.chart-container {
  display: flex;
  width: 100%;
  padding: 0.5rem;
  justify-content: center;

  .loading {
    width: 90%;
  }
}

.chart {
  display: flex;
  width: 90%;
  margin: 0 auto;
}

div.google-visualization-tooltip {
  font-size: 0.8rem;
  background-color: rgb(243, 243, 243);
}

ul {
  padding: 0.5rem 1.3rem;
  margin: 0;
}
</style>

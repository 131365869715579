import { createApp } from "vue";

import mitt from "mitt";

import App from "./App.vue";

import AssetComponent from "./components/AssetComponent.vue";
import ChartComponent from "./components/ChartComponent.vue";
import InfoComponent from "./components/InfoComponent.vue";
import RailCarComponent from "./components/RailCarComponent.vue";
import TrainComponent from "./components/TrainComponent.vue";
import ToolTipComponent from "./components/ToolTipComponent.vue";

const emitter = mitt();
const app = createApp(App);

app.config.globalProperties.emitter = emitter;

app.component("asset-component", AssetComponent);
app.component("chart-component", ChartComponent);
app.component("info-component", InfoComponent);
app.component("railcar-component", RailCarComponent);
app.component("train-component", TrainComponent);
app.component("tooltip-component", ToolTipComponent);

app.mount("#app");

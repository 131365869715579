import axios from "axios";
import { format, subDays } from "date-fns";

const API_URL = "https://trains.pablomag.com";
const QUERY_URL = "https://train-query.pablomag.com";

const getTrainInfo = async (trainName) => {
  const endpoint = `${API_URL}/trains/cargo/${trainName}`;

  const response = await axios.get(endpoint, {
    timeout: 300000
  });

  return response.data.trains;
};

const getLast24hStats = async () => {
  const endpoint = `${QUERY_URL}/v1/transfer/total-rewards-last-24h`;

  const response = await axios.get(endpoint);

  return response.data;
};

const getTrainNPCEncountersByDay = async (year, month, day) => {
  const endpoint = `${QUERY_URL}/v1/transfer?limit=50&page=0&npc=culprit&date_from=${year}-${month}-${day}T00:00:00Z&date_to=${year}-${month}-${day}T23:59:59Z`;

  const response = await axios.get(endpoint);

  return response.data;
};

const getTrainStatsByDay = async (year, month, day) => {
  const endpoint = `${QUERY_URL}/v1/transfer/total-rewards-by-train?date_from=${year}-${month}-${day}T00:00:00Z&date_to=${year}-${month}-${day}T23:59:59Z`;

  const response = await axios.get(endpoint);

  return response.data;
};

const getLastSevenDays = (includeToday = false) => {
  const today = new Date();
  const last7Days = [];
  const startIndex = includeToday ? 0 : 1;
  const endIndex = startIndex + 7;

  for (let i = startIndex; i < endIndex; i++) {
    const previousDay = subDays(today, i);
    const formattedDate = format(previousDay, "dd MM yyyy");

    const [day, month, year] = formattedDate.split(" ");

    last7Days.push({
      year,
      month,
      day
    });
  }

  return last7Days.reverse();
};

const getTodaysRewardsChartData = async () => {
  const { data } = await getLast24hStats();

  const header = ["train", "reward", { role: "tooltip", p: { html: true } }];

  const rows = [];
  data.forEach((item) => {
    const row = [];
    row.push(item._id);
    row.push(item.reward);

    const tooltip = `<ul>
      <div class="main-font">${item._id}</div>
      <li class="square-font tooltip--item">Reward: ${item.reward}</li>
      <li class="square-font tooltip--item">Tips: ${item.tips}</li>
      <li class="square-font tooltip--item">Distance: ${item.distance}</li>
      <li class="square-font tooltip--item">Railruns: ${item.railruns}</li>
    `;

    row.push(tooltip);
    rows.push(row);
  });

  return [header, ...rows];
};

const getLast7DaysRewardsChartData = async () => {
  const timeframe = getLastSevenDays();

  let body = [];
  let header = new Set(["day"]);

  const rows = [];
  for (const value of timeframe) {
    const { data } = await getTrainStatsByDay(
      value.year,
      value.month,
      value.day
    );

    const trains = data.reduce((acc, item) => {
      if (!acc.includes(item._id)) {
        acc.push(item._id);
      }
      return acc;
    }, []);

    header = new Set([...header, ...trains]);
  }

  for (const value of timeframe) {
    const { data } = await getTrainStatsByDay(
      value.year,
      value.month,
      value.day
    );

    body = [`${value.day}/${value.month}/${value.year}`];

    header.forEach((column) => {
      if (column !== "day") {
        const train = data.find((item) => column === item._id);
        const reward = train ? train.reward : 0;

        body.push(reward);
      }
    });

    rows.push(body);
  }

  return [[...header], ...rows];
};

const getLast7DaysDropsChartData = async () => {
  const timeframe = getLastSevenDays(true);

  const header = ["day"];
  const rows = [];
  const drops = [];
  const days = [];
  const trains = [];

  for (const value of timeframe) {
    const { data } = await getTrainNPCEncountersByDay(
      value.year,
      value.month,
      value.day
    );

    const date = `${value.day}/${value.month}/${value.year}`;

    if (!header.includes(date)) {
      days.push(date);
    }
    if (!data) {
      return;
    }

    if (!drops[date]) {
      drops[date] = [];
    }

    data.forEach((item) => {
      if (!trains.includes(item.train)) {
        trains.push(item.train);
        header.push(item.train);
        header.push({ role: "tooltip", p: { html: true } });
      }

      if (!drops[date][item.train]) {
        drops[date][item.train] = { drops: [], qty: 0 };
      }

      drops[date][item.train].drops.push({
        name: item.npc_encounter.reward.name,
        rarity: item.npc_encounter.reward.rarity
      });
      drops[date][item.train].qty += 1;
    });
  }

  days.forEach((day) => {
    const row = [day];

    header.forEach((train) => {
      if (train === "day" || typeof train === "object") {
        return;
      }
      const qty = drops[day][train] ? drops[day][train].qty : 0;
      row.push(qty);

      let dropsHtml = `<ul><div class="main-font">${train}</div>`;
      if (drops[day][train]) {
        drops[day][train].drops.forEach((item) => {
          dropsHtml += `<li class="square-font tooltip--item rarity rarity--${item.rarity}">${item.name}</li>`;
        });
      }
      dropsHtml += "</ul>";

      row.push(dropsHtml);
    });

    rows.push(row);
  });

  return [header, ...rows];
};

const getAssetsAvailable = async () => {
  const endpoint = `${API_URL}/assets/available`;

  const response = await axios.get(endpoint, {
    timeout: 100000
  });

  const data = response.data;

  const passengers = [];
  const locomotives = [];
  const conductors = [];
  const railcars = [];
  const commodities = [];

  data.forEach((asset) => {
    const { immutable_data } = asset.template;

    switch (asset.schema.schema_name) {
      case "commodity":
        commodities.push({
          asset_id: asset.asset_id,
          name: immutable_data.name,
          type: immutable_data.type,
          rarity: immutable_data.rarity,
          volume: immutable_data.volume,
          weight: immutable_data.weight
        });
        break;
      case "locomotive":
        locomotives.push({
          asset_id: asset.asset_id,
          name: immutable_data.name,
          fuel: immutable_data.fuel,
          speed: immutable_data.speed,
          rarity: immutable_data.rarity,
          distance: immutable_data.distance,
          composition: immutable_data.composition,
          hauling_power: immutable_data.hauling_power,
          conductor_threshold: immutable_data.conductor_threshold
        });
        break;
      case "conductor":
        conductors.push({
          asset_id: asset.asset_id,
          name: immutable_data.name,
          perk: immutable_data.perk,
          perk_boost: immutable_data.perk_boost,
          perk2: immutable_data.perk2 || null,
          perk2_boost: immutable_data.perk2_boost || null,
          rarity: immutable_data.rarity,
          conductor_level: immutable_data.conductor_level
        });
        break;
      case "passenger":
        passengers.push({
          asset_id: asset.asset_id,
          name: immutable_data.name,
          rarity: immutable_data.rarity,
          tip: immutable_data.tip,
          criterion: immutable_data.criterion,
          threshold: immutable_data.threshold,
          home_region: immutable_data.home_region,
          home_regionid: immutable_data.home_regionid
        });
        break;
      case "railcar":
        railcars.push({
          asset_id: asset.asset_id,
          name: immutable_data.name,
          rarity: immutable_data.rarity,
          size: immutable_data.size,
          type: immutable_data.type,
          capacity: immutable_data.capacity,
          commodity_type: immutable_data.commodity_type,
          commodity_type2: immutable_data.commodity_type2
        });
        break;
    }
  });

  return {
    commodities,
    locomotives,
    railcars,
    passengers,
    conductors
  };
};

export {
  getTrainInfo,
  getAssetsAvailable,
  getLast7DaysRewardsChartData,
  getLast7DaysDropsChartData,
  getTodaysRewardsChartData
};
